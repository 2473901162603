// WhatsAppButton.js

import React, { useState } from 'react';
import { Fab, Tooltip, Badge, IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

// Styled Floating Button
const FloatingButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  background: 'linear-gradient(135deg, #4CAF50, #8BC34A)', // Bright green gradient
  color: 'white',
  borderRadius: '50%', // Rounded button
  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)', // Deeper shadow for depth
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.4)',
  },
  '&:active': {
    transform: 'scale(0.95)', // Slightly decrease size when pressed
  },
}));

// Styled Message Bubble
const MessageBubble = styled('div')(({ theme }) => ({
  position: 'fixed', // Fixed positioning to float with the button
  bottom: '70px', // Position above the button
  right: '10px', // Align with the button
  backgroundColor: '#ffffff', // White background for contrast
  color: '#333', // Dark text color for readability
  padding: '12px', // Slightly increased padding
  borderRadius: '20px',
  fontSize: '0.9rem', // Base font size
  fontWeight: '600',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  zIndex: 1, // Ensure it appears above other elements
  display: 'flex',
  flexDirection: 'column', // Column direction for text and close button
  alignItems: 'flex-start', // Align text to the left
  justifyContent: 'space-between', // Space between text and close button
  maxWidth: '200px', // Max width for better responsiveness
  wordWrap: 'break-word', // Allow text to wrap
  textAlign: 'center', // Center text for better appearance
}));

const WhatsAppButton = () => {
  const [showMessage, setShowMessage] = useState(true); // State to control message visibility

  const handleJoinChannel = () => {
    const url = 'https://whatsapp.com/channel/0029VakabZD7j6gE680VqN3h';
    window.open(url, '_blank');
  };

  const handleCloseMessage = () => {
    setShowMessage(false); // Close message bubble
  };

  return (
    <>
      {showMessage && (
        <MessageBubble>
          <span> Join Channel for Growth updates! ❣️</span>
          <IconButton onClick={handleCloseMessage} size="small" aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        </MessageBubble>
      )}
      <Tooltip title="Join Channel for Growth Tips!" arrow>
          <FloatingButton onClick={handleJoinChannel} aria-label="Join WhatsApp Channel">
            <WhatsAppIcon />
          </FloatingButton>
      </Tooltip>
    </>
  );
};

export default WhatsAppButton;
