import React, { useState, useEffect, useMemo } from "react";
import {  Box,  Grid,  Button,  Container,  Stack,  Typography,  Card,  CardContent,  CardMedia,  styled,  Link,  TextField,  MenuItem,  Select,  InputLabel,  FormControl,  Chip,  Pagination,} from "@mui/material";
import { Helmet } from "react-helmet";
import { fetchPosts } from "../Features/API";
import LoadingSpinner from "./LoadingSpinner";

// Styled components
const BlogCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: "0.3s",
  "&:hover": {
    boxShadow: theme.shadows[6],
  },
}));

const BlogImage = styled(CardMedia)(({ theme }) => ({
  height: 250,
  background: "round",
  [theme.breakpoints.up("sm")]: {
    height: 250,
    background: "round",
  },
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const BlogDescription = styled("div")(({ theme }) => ({
  color: theme.palette.text.secondary,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  marginTop: theme.spacing(1),
  minHeight: 50,
  maxWidth: "15rem",
  [theme.breakpoints.up("md")]: {
    whiteSpace: "normal",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    maxWidth: "20rem",
  },
}));

const BlogButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  "&:hover": {
              background:"hsla(210, 100%, -65%, 0.1)",
              cursor:'pointer'
              },
}));

// Function to truncate text to approximately 7-10 words
const truncateText = (text, wordLimit = 10) => {
  const words = text.split(" ");
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(" ") + "..."
    : text;
};

// Function to sanitize HTML and return plain text
const sanitizeHTML = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const getUniqueCategories = (posts) => {
  const categoriesSet = new Set(); // To ensure unique categories

  posts.forEach((post) => {
    post.categories.forEach((category) => {
      categoriesSet.add(category.name); // Add category name to the Set
    });
  });

  return Array.from(categoriesSet); // Convert the Set to an array
};

export default function BlogPosts() {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [sortOption, setSortOption] = useState("newest");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const postsPerPage = 9;

  const loadPosts = async () => {
    try {
      const postsData = await fetchPosts();
      setPosts(postsData);
    } catch (error) {
      setError("Failed to fetch posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPosts();
  }, []);

  const filteredPosts = useMemo(() => {
    return posts
      .filter((post) => {
        const sanitizedDescription = sanitizeHTML(
          post.content.rendered
        ).toLowerCase();
        const lowerSearchTerm = searchTerm.toLowerCase();
        return (
          post.title.rendered.toLowerCase().includes(lowerSearchTerm) ||
          sanitizedDescription.includes(lowerSearchTerm) ||
          post.categories.some((category) =>
            category.name.toLowerCase().includes(lowerSearchTerm)
          )
        );
      })
      .filter((post) => {
        const postDate = new Date(post.date).toISOString().split("T")[0]; // Extract only the 'YYYY-MM-DD' part

        // Parse the dateFilter if it exists, and extract the 'YYYY-MM-DD' part
        const filterDate = dateFilter
          ? new Date(dateFilter).toISOString().split("T")[0]
          : "";

          return (
            (!categoryFilter || post.categories.some((category) => category.name === categoryFilter)) &&
            (!dateFilter || postDate === filterDate)
          );
          
      });
  }, [posts, searchTerm, categoryFilter, dateFilter]);

  const sortedPosts = useMemo(() => {
    return [...filteredPosts].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
  
      if (sortOption === "newest") {
        // Sort posts by newest date
        return dateB - dateA; // Newer dates come first
      } else if (sortOption === "oldest") {
        // Sort posts by oldest date
        return dateA - dateB; // Older dates come first
      } else if (sortOption === "title-asc") {
        // Sort posts by title A-Z
        return a.title.rendered.localeCompare(b.title.rendered);
      } else if (sortOption === "title-desc") {
        // Sort posts by title Z-A
        return b.title.rendered.localeCompare(a.title.rendered);
      }
      return 0; // Default case if no sort option matches
    });
  }, [filteredPosts, sortOption]);
  
  const currentPosts = useMemo(() => {
    const indexOfLastPost = page * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    return sortedPosts.slice(indexOfFirstPost, indexOfLastPost);
  }, [page, sortedPosts]);

  const uniqueCategories = useMemo(() => getUniqueCategories(posts), [posts]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle category filter change
  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  // Handle date filter change
  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

  // Handle sort option change
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const generateKeywords = (currentPosts) => {
    const staticKeywords = [
      "SITInovations",
      "Sanskaar IT Inovations",
      "Website development in Bikaner",
      "Digital Marketing",
      "Web Development",
      "SEO Services",
      "Technology Blog",
      "Web Design",
    ];

    // Extracting category names and tag names from posts
    const dynamicCategories = currentPosts
      .map((post) => post.categories.map((category) => category.name))
      .flat();
    const dynamicTags = currentPosts
      .map((post) => post.tags.map((tag) => tag.name))
      .flat();

    // Removing duplicates from categories and tags
    const uniqueCategories = [...new Set(dynamicCategories)];
    const uniqueTags = [...new Set(dynamicTags)];

    // Combine static keywords with dynamic categories and tags
    const dynamicKeywords = [...uniqueCategories, ...uniqueTags];

    return `${staticKeywords.join(", ")}, ${dynamicKeywords.join(", ")}`;
  };

  if (loading) {
    return <LoadingSpinner message="Brewing some fresh content for you..." />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {posts.length === 0 ? (
        <p>No Blog Post Found</p>
      ) : (
        <>
          {/* Helmet and Search/Filter sections omitted for brevity */}
          <Helmet>
            <meta name="keywords" content={generateKeywords(currentPosts)} />
          </Helmet>

          <Box
            sx={(theme) => ({
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
              ...theme.applyStyles("dark", {
                backgroundImage:
                  "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
              }),
              pt: 8,
              pb: 8,
            })}
          >
            <Container>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  mt: 4,
                }}
              >
                Discover Our Latest Insights
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  mb: 4,
                  color: "text.primary",
                  maxWidth: "800px",
                  marginX: "auto",
                }}
              >
                Fresh vibes, cool reads, and everything in between. Dive in and
                explore — it's all here waiting for you!
              </Typography>

              {/* Search and Filter Section */}
              <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Search"
                      variant="outlined"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                value={categoryFilter}
                onChange={(event) => setCategoryFilter(event.target.value)}
                label="Category"
              >
                <MenuItem value="">All Categories</MenuItem>
                {uniqueCategories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Filter by Date"
                      variant="outlined"
                      value={dateFilter}
                      onChange={handleDateChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>Sort By</InputLabel>
                      <Select
                        value={sortOption}
                        onChange={handleSortChange}
                        label="Sort By"
                      >
                        <MenuItem value="newest">Newest</MenuItem>
                        <MenuItem value="oldest">Oldest</MenuItem>
                        <MenuItem value="title-asc">Title (A-Z)</MenuItem>
                        <MenuItem value="title-desc">Title (Z-A)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="stretch"
              >
                {currentPosts.map((post, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <BlogCard
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                        },
                      }}
                    >
                      <Link href={`/blogs/${post.slug}`} underline="none">
                        <BlogImage
                          image={
                            post.media.source_url || "path/to/default/image.jpg"
                          }
                          title={post.title.rendered}
                          sx={{
                            width: "100%",
                            aspectRatio: "16/9",
                            objectFit: "cover",
                            borderRadius: "4px 4px 0 0",
                          }}
                        />
                      </Link>
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Link href={`/blogs/${post.slug}`} underline="none">
                          <BlogTitle
                            variant="h6"
                            sx={{ marginBottom: "16px", fontWeight: "bold" }}
                          >
                            {truncateText(post.title.rendered)}
                          </BlogTitle>
                        </Link>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            overflowX: "auto",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": { display: "none" },
                            "-ms-overflow-style": "none",
                            marginBottom: "16px",
                          }}
                        >
                          {post.categories.map((category, index) => (
                            <CategoryChip
                              key={category.id}
                              label={category.name}
                              color="primary"
                              size="small"
                              sx={{ cursor: "pointer" }}
                            />
                          ))}
                        </Stack>
                        <BlogDescription sx={{ textAlign: "justify" }}>
                          {truncateText(sanitizeHTML(post.content.rendered))}
                        </BlogDescription>
                      </CardContent>
                      <BlogButton
                        variant="outlined"
                        href={`/blogs/${post.slug}`}
                        sx={{ marginTop: "auto" }}
                      >
                        Read More
                      </BlogButton>
                    </BlogCard>
                  </Grid>
                ))}
              </Grid>

              {sortedPosts.length > 0 && (
                <Stack
                  spacing={2}
                  sx={{ mt: 4 }}
                  direction="row"
                  justifyContent="center"
                >
                  <Pagination
                    count={Math.ceil(sortedPosts.length / postsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              )}
            </Container>
          </Box>
        </>
      )}
    </>
  );
}
