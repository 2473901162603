import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  styled,
  Chip,
  Stack,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import { fetchPostByCategories } from '../Features/API';
import LoadingSpinner from './LoadingSpinner';
import { Link } from 'react-router-dom';


// Styled components
const PostCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 300,
  margin: theme.spacing(1),
  boxShadow: theme.shadows[3],
  transition: '0.3s',
  height: 320, // Fixed height to ensure uniformity
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const PostImage = styled(CardMedia)(({ theme }) => ({
  height: 180,
  backgroundSize: 'cover',
  borderRadius: theme.shape.borderRadius,
}));

const PostTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textAlign: 'center',
  marginTop: theme.spacing(1),
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundRepeat: 'no-repeat',
  paddingTop: 16,
  paddingBottom: 16,
}));

const RelatedPosts = ({ currentPostCategories }) => {
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadRelatedPosts = useCallback(async () => {
    try {
      const relatedPostData = await fetchPostByCategories(currentPostCategories);
      setRelatedPosts(relatedPostData);
    } catch (error) {
      setError('Oops! Something went wrong while fetching related posts. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [currentPostCategories]);

  useEffect(() => {
    loadRelatedPosts();
  }, [loadRelatedPosts]);

  if (loading) {
    return (
      <ResponsiveBox>
        <Container>
          <LoadingSpinner message='Fetching related content for you...' />
        </Container>
      </ResponsiveBox>
    );
  }

  if (error) {
    return (
      <ResponsiveBox>
        <Container>
          <Typography variant="h5" color="error" align="center">{error}</Typography>
        </Container>
      </ResponsiveBox>
    );
  }

  return (
<Box
  sx={(theme) => ({
    pt: 8,
    pb: 8,
    backgroundColor: theme.palette.background.paper,
  })}
>
  <Container>
    <Typography variant="h4" gutterBottom>
      Related Posts
    </Typography>
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {relatedPosts.map((post) => (
        <Link to={`/Blogs/${post.slug}`} key={post.slug} style={{ textDecoration: 'none' }}>
          <PostCard>
            <PostImage image={post.media.source_url} title={post.title.rendered} />
            <CardContent>
              <PostTitle variant="h6" noWrap>
                {post.title.rendered}
              </PostTitle>
              <Stack direction="row" spacing={0.5} sx={{ mt: 1, flexWrap: 'nowrap', overflowX: 'auto' }}>
                {post.categories.map((category) => (
                  <CategoryChip key={category.id} label={category.name} color="primary" size="small" />
                ))}
              </Stack>
            </CardContent>
          </PostCard>
        </Link>
      ))}
    </Box>
  </Container>
</Box>


  );
};

export default RelatedPosts;
