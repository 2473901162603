import { CircularProgress, Box, Typography } from '@mui/material';

const LoadingSpinner = ({ message = 'Loading...', size = 40 }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight="50vh" // Ensures the loading spinner is vertically centered
    >
      <CircularProgress size={size} color="primary" />
      <Typography variant="h6" color="textSecondary" mt={2}>
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingSpinner;
