import config from './config';
import axios from 'axios';

// Base URL for API requests
const apiUrl = config.apiUrl;

// Caching variables
let cachedCategories = {};
let cachedMedia = {};
let cachedTags = {};

// Function to fetch categories with caching
export const fetchCategories = async (id) => {
  if (Object.keys(cachedCategories).length === 0) {
    try {
      const response = await axios.get(`${apiUrl}/categories`);
      cachedCategories = response.data.reduce((map, category) => {
        map[category.id] = category
        return map;
      }, {});
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  }

  if (id) {
    return id.map(categoryId => cachedCategories[categoryId] || { id: categoryId, name: "uncategorised" });
  }
  return Object.values(cachedCategories);
};

// Function to fetch tags with caching
export const fetchTags = async (tag) => {
  if (Object.keys(cachedTags).length === 0) {
    try {
      const response = await axios.get(`${apiUrl}/tags`);
      cachedTags = response.data.reduce((map, tag) => {
        map[tag.id] = tag;
        return map;
      }, {});
    } catch (error) {
      console.error('Error fetching tags:', error);
      throw error;
    }
  }

  if (tag) {
    return tag.map(tagId => cachedTags[tagId] || { id: tagId, name: "uncategorised" });
  }
  return Object.values(cachedTags);
};

// Function to fetch media with caching
export const fetchMedia = async (id) => {
  if (Object.keys(cachedMedia).length === 0) {
    try {
      const response = await axios.get(`${apiUrl}/media`);
      cachedMedia = response.data.reduce((map, mediaItem) => {
        map[mediaItem.id] = mediaItem;
        return map;
      }, {});
    } catch (error) {
      console.error('Error fetching media:', error);
      throw error;
    }
  }

  return id ? cachedMedia[id] || { source_url: "/image.png" } : cachedMedia;
};

// Function to fetch all blog posts
export const fetchPosts = async () => {
  try {
    const response = await axios.get(`${apiUrl}/posts?per_page=100`);
    
    const categories = await fetchCategories();
    const media = await fetchMedia();
    const tags = await fetchTags();

    const posts = response.data.map(post => ({
      ...post,
      categories: post.categories.map(categoryId => ({
        id: categoryId,
        ...cachedCategories[categoryId] || { name: "uncategorised" }
      })),
      media: cachedMedia[post.featured_media] || { source_url: "/image.png" },
      tags: post.tags.map(tagId => ({
        id: tagId,
        ...cachedTags[tagId] || { name: "uncategorised" }
      })),
    }));

    return posts;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

// Function to fetch a single blog post by slug
export const fetchPost = async (slug) => {
  try {
    const response = await axios.get(`${apiUrl}/posts?slug=${slug}`);
    const postData = response.data[0]; // Assuming the slug returns a single post

    if (!postData) {
      throw new Error("Post not found");
    }

    const categories = await fetchCategories(postData.categories);
    const media = await fetchMedia(postData.featured_media);
    const tags = await fetchTags(postData.tags);

    const post = {
      ...postData,
      categories,
      media,
      tags,
    };

    console.log("single post ", post);
    return post;
  } catch (error) {
    console.error('Error fetching post:', error);
    throw error;
  }
};

// Function to fetch posts by categories
export const fetchPostByCategories = async (categoriesId) => {
  try {
    const response = await axios.get(`${apiUrl}/posts?categories=${categoriesId.join(',')}`);
    const posts = await Promise.all(response.data.map(async post => {
      const categories = await fetchCategories(post.categories);
      const media = await fetchMedia(post.featured_media);
      return {
        ...post,
        categories,
        media,
      };
    }));
    return posts;
  } catch (error) {
    console.error('Error fetching posts by categories:', error);
    throw error;
  }
};

// Function to fetch a single blog post by ID
export const fetchPostById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/posts/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching post with ID ${id}:`, error);
    throw error;
  }
};

// You can add more functions for other API calls as needed
