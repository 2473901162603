import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Divider,
  styled,
  Chip,
  Stack,
  IconButton,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import RelatedPosts from "./RelatedPosts";
import { fetchPost } from "../Features/API";
import { useState, useEffect, useCallback } from "react";
import LoadingSpinner from "./LoadingSpinner";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Styled components
const BlogImage = styled(CardMedia)(({ theme }) => ({
  height: 500,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  backgroundSize: "contain", // Ensures the image fits completely
  backgroundPosition: "center", // Centers the image
  backgroundRepeat: "no-repeat", // No repetition
  [theme.breakpoints.up("sm")]: {
    height: 500,
    backgroundSize: "contain", // Ensures the image fits completely
    backgroundPosition: "center", // Centers the image
    backgroundRepeat: "no-repeat", // No repetition
  },
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const BlogContent = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: "pre-wrap",
  lineHeight: 1.8,
  "& img": {
    maxWidth: "100%",
    height: "auto",
    display: "block",
    margin: "20px 0",
    borderRadius: theme.shape.borderRadius,
  },
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  background:"hsla(210, 100%, 65%, 0.1)",
  color: "#fff",
  "&:hover": {
              background:"hsla(210, 100%, -65%, 0.1)",
              cursor:'pointer'
              },
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundRepeat: "no-repeat",
  paddingTop: 16,
  paddingBottom: 16,
}));

const ResponsiveCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
}));

// Function to sanitize HTML content
const sanitizeHTML = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};
// Function to truncate text to a specified number of words
const truncateText = (text, wordLimit = 10) => {
  const words = text.split(" ");
  return words.length > wordLimit
    ? `${words.slice(0, wordLimit).join(" ")}...`
    : text;
};

function cleanHTML(content) {
  if (!content) return ""; // Return empty string if content is not available

  // Remove consecutive new lines and empty paragraphs
  const cleanedHTML = content
    .replace(/(\r\n|\n|\r){2,}/g, "") // Remove consecutive new lines
    .replace(/<p>\s*<\/p>/g, ""); // Remove empty paragraphs

  return cleanedHTML;
}

// Single Blog Post Page Component
export default function SingleBlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate();

  const loadPost = useCallback(async () => {
    try {
      const postData = await fetchPost(slug);
      setPost(postData);
    } catch (error) {
      setError(
        "Oops! Something went wrong while fetching the post. Please try again later."
      );
      console.log(error)
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    loadPost();
  }, [loadPost]);

  const generateKeywords = (title, categories, tags) => {
    const titleKeywords = title.split(" ").slice(0, 5).join(", ") || ""; // Extract first 5 words

    // Extract category names from category objects
    const categoryKeywords = categories
      .map((category) => category.name)
      .join(", "); // Join categories into a string

    // Extract tag names from tag objects
    const tagKeywords = tags.map((tag) => tag.name).join(", "); // Join tags into a string

    return `${titleKeywords}, ${categoryKeywords}, ${tagKeywords}, Sanskaar IT Innovations, SITInovations, Website Development in Bikaner, Bikaner`;
  };

  const sharePost = (platform) => {
    const url = `https://www..sitinovations.in/Blogs/${slug}`;
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`;
        break;
      case "instagram":
        alert(
          "Instagram sharing is not available through a direct link. Please copy the URL and share it on your Instagram."
        );
        return;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          url
        )}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          url
        )}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };

  if (loading) {
    return (
      <ResponsiveBox>
        <Container>
          <LoadingSpinner message="Hang tight! We are fetching the latest content for you..." />
        </Container>
      </ResponsiveBox>
    );
  }

  if (error) {
    return (
      <ResponsiveBox>
        <Container>
          <Typography variant="h5" color="error" align="center">
            {navigate('/not-found')}
          </Typography>
        </Container>
      </ResponsiveBox>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title.rendered} | Sanskaar IT Inovations | SITInovations</title>
        <meta
          name="description"
          content={truncateText(sanitizeHTML(cleanHTML(post.content.rendered)), 25)}
        />
        <meta property="og:title" content={`${post.title.rendered} | SITInovations`} />
        <meta
          property="og:description"
          content={truncateText(sanitizeHTML(cleanHTML(post.content.rendered)), 25)}
        />
        <meta property="og:image" content={post.media.source_url} />
        <meta property="og:site_name" content="Sanskaar IT Inovations" />
        <meta
          property="og:url"
          content={`https://www..sitinovations.in/Blogs/${slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title.rendered} />
        <meta
          name="twitter:description"
          content={truncateText(sanitizeHTML(cleanHTML(post.content.rendered)), 15)}
        />
        <meta name="twitter:image" content={post.media.source_url} />
        <meta
          name="keywords"
          content={generateKeywords(
            post.title.rendered,
            post.categories,
            post.tags
          )}
        />

<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": post.title.rendered, // Assuming the post title is dynamic
    "datePublished": post.date, // Dynamic publish date
    "author": {
      "@type": "Person",
      "name": "Sanskaar" // You can replace with dynamic author name if available
    },
    "publisher": {
      "@type": "Organization",
      "name": "Sanskaar IT Inovations", // Replace with dynamic organization name if needed
      "logo": {
        "@type": "ImageObject",
        "url": "https://www..sitinovations.in/logo.png" // Replace with dynamic logo URL
      }
    },
    "image": post.media.source_url || "https://www..sitinovations.in/logo.png", // Dynamic image URL
    "url": window.location.href // Dynamic URL
  })}
</script>


        
      </Helmet>
      <ResponsiveBox>
        <Container sx={{ my: 11 }}>
          <ResponsiveCard>
            <BlogImage
              image={post.media.source_url}
              title={post.title.rendered}
            />
            <CardContent>
              <BlogTitle
                variant="h3"
                sx={{ fontSize: "clamp(1rem, 8vw, 3.5rem)" }}
              >
                {post.title.rendered}
              </BlogTitle>
              <Typography variant="body2" color="text.secondary" align="center">
                {`By `}
                <Link
                  href="https://www..sitinovations.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <strong>SITInovations</strong>
                </Link>
                {` on ${new Date(post.date).toLocaleDateString()}`}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 2, justifyContent: "center" }}
              >
                {post.categories?.map((category, index) => (
                  <CategoryChip
                    key={category.id}
                    label={category.name}
                    color="primary"
                    size="small"
                  />
                ))}
              </Stack>
              <Divider />
              <BlogContent
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(post.content.rendered),
                }}
              />
              <Divider />
              <Typography variant="body2" color="text.secondary" align="center">
                Thank you for reading! If you found this article helpful, share
                it with your friends.
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 2, justifyContent: "center" }}
              >
                <IconButton onClick={() => sharePost("facebook")}>
                  <FacebookIcon />
                </IconButton>
                <IconButton onClick={() => sharePost("instagram")}>
                  <InstagramIcon />
                </IconButton>
                <IconButton onClick={() => sharePost("linkedin")}>
                  <LinkedInIcon />
                </IconButton>
                <IconButton onClick={() => sharePost("twitter")}>
                  <TwitterIcon />
                </IconButton>
                <IconButton onClick={() => sharePost("whatsapp")}>
                  <WhatsAppIcon />
                </IconButton>
              </Stack>
            </CardContent>
          </ResponsiveCard>
        </Container>
      </ResponsiveBox>
      <RelatedPosts
        currentPostCategories={post.categories.map((category) => category.id)}
      />
    </>
  );
}
