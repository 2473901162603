import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Posts from '../components/Posts';
import { Helmet } from 'react-helmet';


export default function Blogs() {
  

  return (
    <>
    <Helmet>
        <title>Blog Posts | Sanskaar IT Inovations | SITInovations</title>
        <meta name="description" content="Fresh vibes, cool reads, and everything in between. Dive in and explore — it's all here waiting for you!" />
        <meta property="og:title" content="Blogs | Sanskaar IT Inovations | SITInovations" />
        <meta property="og:description" content="Fresh vibes, cool reads, and everything in between. Dive in and explore — it's all here waiting for you!" />
        <meta property="og:image" content="https://www..sitinovations.in/logo.png" />
        <meta property="og:url" content="https://www..sitinovations.in/blogs" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blogs| Sanskaar IT Inovations | SITInoatinos" />
        <meta name="twitter:description" content="Check out our latest blog posts covering topics from digital marketing to web development. Stay informed and ahead with Sanskaar IT Inovations." />
        <meta name="twitter:image" content="https://www..sitinovations.in/logo.png" />
      </Helmet>
      <Box id='Blogs' sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}>
        <Divider />
        <Posts/>
      </Box>
      </>
  );
}
