import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SingleBlogPost from '../components/SinglePost';

export default function SingleBlog() {

  return (
      <Box id='contact-us' sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}>
        <Divider />
        <SingleBlogPost />
      </Box>
  );
}
